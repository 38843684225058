import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Tabs, Form, Input, Button, Checkbox, Tag } from 'antd';

import { login, LoginParams } from '../../api';

import {useLoginSMSToken} from "../../components/SendSMSToken";

import welcomeWebp from '../../assets/video/welcome.webm';
import user from '../../assets/images/user.svg';
import lock from '../../assets/images/lock.svg';
import phone from '../../assets/images/phone.svg';
import code from '../../assets/images/code.svg';

import styles from './index.module.scss';
import Helmet from 'react-helmet';
import { ResponseCodeError } from '@maxtropy/central-commons-ui';

const { TabPane } = Tabs;
const { useForm } = Form;

const rememberUserNamekey = "cas-login:rememberUserName";
const userNameKey = "cas-login:username";

const commonPhoneRegex = /^(?:1\d{10}|0\d{2,3}-?\d{7,8})$/;

interface CodeParams {
  cellphone: string;
  code: string;
}

const Login: React.FC = () => {
  const [form] = useForm();
  const [formCode] = useForm();
  const [userName, setUserName] = useState<string>('');
  const [remeber, setRemeber] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const history = useHistory();

  const {
    canSendSMS,
    sendSMS,
    smsSending,
    smsCoolDown,
    setCellphone
  } = useLoginSMSToken();
  
  useEffect(() => {
    const rememberUserName = localStorage.getItem(rememberUserNamekey);
    if (rememberUserName === null) {
      setRemeber(true)
    } else if (rememberUserName === "true") {
      const userName = localStorage.getItem(userNameKey) || ''
      setUserName(userName);
      setRemeber(true);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      username: userName,
    });
  }, [form, userName]);

  const errorHandler = useCallback((e: Response | ResponseCodeError, defaultDesc = '请输入正确的账号或手机号和密码。') => {
    if (e instanceof ResponseCodeError) {
      if (e.status === 403) {
        setErr('这个账户被禁用了。');
      }
      if (e.status === 412) {
        // 跳转页面
        history.push('/activate?back=true');
      }
      return
    }
    if (e.status === 401) {
      setErr(defaultDesc);
    }
  }, [history])


  const onPassFinish = (values: LoginParams) => {
    if (remeber) {
      localStorage.setItem(rememberUserNamekey, "true");
      localStorage.setItem(userNameKey, values.username);
    } else {
      localStorage.setItem(rememberUserNamekey, "false");
      localStorage.removeItem(userNameKey)
    }
    login(values).catch((e) => {errorHandler(e)});
  }

  const onCodeFinish = (value: CodeParams) => {
    const codeValue:LoginParams = {
      username: `OTP_SMS/${value.cellphone}`,
      password: value.code
    }
    login(codeValue).catch((e) => {errorHandler(e, '验证码不正确')});
  }

  return (
    <div className={styles.loginWrapper}>
      <Helmet>
        <title>登录</title>
      </Helmet>
      <div className={styles.content}>
        <div className={styles.loginHeader}>
          <div className={styles.headerTitle}>工业赋能 模式创新</div>
          <div className={styles.headerSubTitle}>打造闭环赋能体系，共建工业互联网生态，落地产业应用！</div>
        </div>
        <div className={styles.display} id="display">
          {/* <div className="display-image"></div> */}
          <video id="display-video" className={styles.displayVideo} muted src={welcomeWebp} autoPlay loop></video>
        </div>
        <div id={styles.loginForm} style={{boxSizing: "border-box"}}>
          <div className={styles.cardForm}>
            <div className={styles.cardFormTitle}>
              <h2 className={styles.mainTitle}>工业互联网统一登录中心</h2>
              <h5 className={styles.subTitle}>欢迎登录</h5>
            </div>
            {
              err && (
                <Tag className={styles.tag} color="#f8d7da" closable onClose={() => {setErr('')}}>
                  {err}
                </Tag>
              )
            }
            <div className={styles.carFormBody}>
              <Tabs defaultActiveKey="1"
                tabBarStyle={{marginBottom: 30}}
                onChange={(activeKey: string) => {
                  setErr('')
                }}
              >
                <TabPane tab="密码登录" key="1">
                  <div className="formPass">
                    <Form form={form} onFinish={onPassFinish}>
                      <Form.Item name="username"
                        rules={[
                          {required: true, message: "账号不能为空"},
                          {whitespace: true, message: "账号不能为空字符"}
                        ]}
                      >
                        <Input placeholder="账号/手机号" size="large"
                          prefix={<img src={user} className="basic-form-prefix-icon" alt=""/>}
                        />
                      </Form.Item>
                      <Form.Item name="password"
                        rules={[
                          {required: true, message: "密码不能为空"},
                          {whitespace: true, message: "密码不能为空字符"}
                        ]}
                      >
                        <Input.Password placeholder="密码" size="large" 
                          prefix={<img src={lock} className="basic-form-prefix-icon" alt=""/>}
                        />
                      </Form.Item>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox checked={remeber} onChange={(e) => {setRemeber(e.target.checked)}}>记住账号</Checkbox>
                        <Link to="/forgot-password?back=true">忘记密码？</Link>
                      </div>
                      <Form.Item>
                        <Button htmlType="submit" className={styles.button} type="primary" size="large">登录</Button>
                      </Form.Item>
                    </Form>
                  </div>
                </TabPane>
                <TabPane tab="短信登录" key="2">
                  <Form form={formCode} onFinish={onCodeFinish}>
                    <Form.Item name="cellphone"
                      rules={[
                        {pattern: commonPhoneRegex, message: '请输入正确的手机号'},
                        {required: true, message: "手机号不能为空"},
                        {whitespace: true, message: "手机号不能为空字符"}
                      ]}
                    >
                      <Input placeholder="手机号" size="large"
                        autoComplete="off"
                        prefix={<img src={phone} className="basic-form-prefix-icon" alt=""/>}
                        onChange={e => setCellphone(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item name="code"
                      rules={[
                        {required: true, message: "验证码不能为空"},
                        {whitespace: true, message: "验证码不能为空字符"}
                      ]}
                    >
                      <Input placeholder="输入验证码" size="large"
                        autoComplete="off"
                        prefix={<img src={code} className="basic-form-prefix-icon" alt=""/>}
                        suffix={<Button type="link" onClick={sendSMS} disabled={!canSendSMS} loading={smsSending}>{
                          smsSending ? "发送中" :
                            smsCoolDown > 0 ?
                              `${smsCoolDown}秒后可重发` :
                              "获取验证码"
                        }</Button>}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" className={styles.button} type="primary" size="large">登录</Button>
                    </Form.Item>
                  </Form>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.bottomText}>
        &copy;2022 江苏极熵物联科技有限公司&ensp;<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备17068181号-2</a>
      </p>
    </div>
  )
}

export default Login;