import {fetch} from "@maxtropy/central-commons-ui";
import qs from 'qs'

export enum AFSErrorCode {
  SUCCESS_1 = "100",
  SUCCESS_2 = "200",
  NEED_NC = "400",
  BLOCK_1 = "800",
  BLOCK_2 = "900",
}

export interface SMSTokenResponse {
  limit: number;
}

export function sendSMSToken(cellphone: string): Promise<SMSTokenResponse> {
  return fetch("/api/selfmgmt/sendtoken", {
    method: "POST",
    body: JSON.stringify({
      cellphone
    })
  });
}

export function sendLoginSMSToken(cellphone: string): Promise<SMSTokenResponse> {
  return fetch("/api/login", {
    method: "POST",
    body: JSON.stringify({
      cellphone
    })
  });
}

export interface ActivateResetPasswordRequest {
  code: string;
  oldPassword: string;
  cellphone: string;
  newPassword: string;
}

export function activateResetPassword(request: ActivateResetPasswordRequest): Promise<void> {
  return fetch("/api/selfmgmt/resetpassword", {
    method: "POST",
    body: JSON.stringify(request)
  });
}

export interface TokenResponse {
  token: string;
}

export function getVerifyCellphoneToken(cellphone: string, code: string): Promise<TokenResponse> {
  return fetch("/api/selfmgmt/verifytoken", {
    method: "POST",
    body: JSON.stringify({
      code,
      cellphone
    })
  });
}

export interface forgotResetPasswordRequest {
  cellphone: string;
  newPassword: string;
  token: string;
}

export function forgotResetPassword(request: forgotResetPasswordRequest): Promise<void> {
  return fetch("/api/selfmgmt/forgetpassword", {
    method: "POST",
    body: JSON.stringify(request)
  });
}

interface WXIdentity {
  avatarUrl: string;
  city: string;
  country: string;
  gender?: string;
  language: string;
  nickname: string;
  province: string;
}

export interface User {
  muid: string;
  name: string;
  username: string;
  cellphone: string;
  expired: boolean;
  disabled: boolean;
  weixinIdentity: WXIdentity | null
}

export function getCurrentUserInfo(): Promise<User> {
  return fetch(`/api/selfmgmt`);
}

export function updateCurrentUser(name: string, cellphone: string): Promise<void> {
  return fetch("/api/selfmgmt/update", {
    method: "PUT",
    body: JSON.stringify({
      name,
      cellphone,
    }),
  });
}

export function bindWx(appId: string, wxSessionCode: string) {
  return fetch(`/api/weixin-auth/bind?appId=${appId}&wxSessionCode=${wxSessionCode}`, {
    method: "POST",
  });
}


// 微信解绑
export function unBindWX(): Promise<void> {
  return fetch(`/api/weixin-auth/unbind`, {
    method: "POST",
  });
}

export interface LoginParams {
  username: string;
  password: string;
}

export async function login(params: LoginParams): Promise<void> {
  try {
    await loginCheck(params)
  } catch (e) {
    throw e
  }
  // await loginCheck(params).catch(e => {throw e});
  const search = window.location.search;
  const parseSearch: {service: string} = qs.parse(search.replace('?', ''))
  const res = await window.fetch(`/cas/v1/tickets`, {
    method: "POST",
    body: qs.stringify(params),
    headers: {
      'accept': 'application/json',
      'Content-type': 'Application/x-www-form-urlencoded'
    }
  }).catch(e => {throw res})
  if (res.status === 201) {
    const location = res.headers.get("location")
    const parseLocation = new URL(location as string);
    if (!navigator.userAgent.includes('miniProgram')) {
      document.cookie = `TGC=${parseLocation.pathname.replace('/cas/v1/tickets/', '')}; Path=/cas/; Secure`;
    }
    const ticketRes = await window.fetch(`${parseLocation.pathname}${window.location.search}`, {
      method: "POST"
    })
    if (ticketRes.status !== 200) {
      throw ticketRes;
    }
    const ticket = await ticketRes.text();
    window.location.replace(parseSearch.service + `&ticket=${ticket}`)
  } else {
    throw res
  }
}

export async function loginCheck(params: LoginParams): Promise<any> {
  return fetch(`/api/login/check`, {
    method: "POST",
    body: JSON.stringify(params),
  })
}
