import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import './App.scss';
import Activation from "./pages/Activation";
import ResetPassword from "./pages/ResetPassword";
import EditProfile from "./pages/EditProfile";
import Account from "./pages/Account";

import WxBind from './pages/WxBind';

import Login from './pages/Login';

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/manage-account" exact>
            <Account/>
          </Route>
          <Route path="/login" exact>
            <Login/>
          </Route>
          <Route path="/activate" exact>
            <Activation/>
          </Route>
          <Route path="/forgot-password" exact>
            <ResetPassword/>
          </Route>
          <Route path="/edit-profile" exact>
            <EditProfile/>
          </Route>
          <Route path="/wx-bind" exact>
            <WxBind/>
          </Route>
          <Route path="/" exact>
            <Redirect to="/manage-account"/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
